export default {
    namespaced: true,
    state: {
        menu: [
            { product: true, title: 'Commentary Management Application', bgcolor: 'caccounting' },
            // { button: true, title: 'Create Analysis', href: '/tds/ar#add' },
            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            // {
            //     title: 'Dashboard', icon: 'mdi mdi-chart-box', href: '/varianceanalysis/dashboard',
            // },
            {
                title: 'Actual Cycle', icon: 'mdi mdi-format-list-group', href: '/varianceanalysis/actualanalysis',
            },
            {
                title: 'Forecast Cycle', icon: 'mdi mdi-format-list-group', href: '/varianceanalysis/forecastanalysis',
            },
            {
                title: 'My Reporting Units', icon: 'mdi mdi-format-list-bulleted', href: '/varianceanalysis/myreportingunits',
            },
            // { divider: true },
            { header: true, title: 'Admin' },
            {title: 'Materiality', icon: 'mdi mdi-cash', href: '/varianceanalysis/threshold', right: "commentary", action: ["admin"]},
            {title: 'Access management', icon: 'mdi mdi-layers', href: '/varianceanalysis/accessrights', right: "commentary", action: ["admin"]},
            {title: 'GL Selection', icon: 'mdi mdi-order-bool-ascending-variant', href: '/varianceanalysis/glselection', right: "commentary", action: ["admin"]},
            {title: 'GL List', icon: 'mdi mdi-order-bool-ascending-variant', href: '/varianceanalysis/glmanagement', right: "commentary", action: ["superadmin"]},
            {title: 'Approvals', icon: 'mdi mdi-check-circle', href: '/varianceanalysis/approvals', right: "commentary", action: ["approve"]},
            {title: 'User Permissions', icon: 'mdi mdi-cash', href: '/varianceanalysis/userpermission', right: "commentary_permission_user"},
            {title: 'Data Processing', icon: 'mdi mdi-database-cog-outline', href: '/varianceanalysis/dataprocessing', right: "commentary_permission_user"},
            {title: 'Email Notification', icon: 'mdi mdi-email-alert', href: '/varianceanalysis/emailnotification', right: "commentary",action: ["superadmin"]},
        ],
        filter: {},
        forecastfilter: {},
        actualfilter: {},
        actualhierarchydata: {},
        forecasthierarchydata: {},
        previousActualFilterState:{},
        previousForecastFilterState: {},
        actualFilterStatus: [],
        forecastFilterStatus: [],
    },
    mutations: {
        setFilters(state, d) {
            state.filter = d
        },
        setForecastFilters(state, d) {
            state.forecastfilter = d
        },
        setActualFilters(state, d) {
            state.actualfilter = d
        },
        setForecastFiltersHieararchy(state, d) {
            state.forecasthierarchydata = d
        },
        setActualFiltersHieararchy(state, d) {
            state.actualhierarchydata = d
        },
        setPreviousActualFilterState(state,d) {
            state.previousActualFilterState = d;
        },
        setPreviousForecastFilterState(state, d) {
            state.previousForecastFilterState = d;
        },
        addActualFilterStatus(state, d){
            state.actualFilterStatus.push(d)
        },
        popActualFilterStatus(state){
            console.log('---index.js------')
            console.log(state.actualFilterStatus)
            state.actualFilterStatus.pop()

        },
        addForecastFilterStatus(state, d) {
            state.forecastFilterStatus.push(d)
        },
        popForecastFilterStatus(state) {
            console.log('---index.js------')
            console.log(state.forecastFilterStatus)
            state.forecastFilterStatus.pop()

        }
    },
    actions: {
    },
    getters: {

    },
}